import React, { Component, useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../css/style.css'
import expand from '../Images/expand.png'
import arrow from '../Images/arrow.png'
import refresh from '../Images/refresh.png'
import shell from '../Images/shell.png'
import node from '../Images/node.png'
import python from '../Images/python.png'
import ruby from '../Images/ruby.png'
import php from '../Images/php.png'
import { Link } from 'react-router-dom';
import GetSidebar from './GetSidebar';
import Header from './Header';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import Footer from './Footer';
import createapi from '../Images/createapi.png'
import apipayment from '../Images/createapi.png'
import apilist from '../Images/apilist.png'
import Layout from './Layout/Layout';


const Docs =()=> {
  const [phrase,setPhrase] = useState();
  const [success,setSuccess] = useState();
  const [timestamp,setTimeStamp] = useState()
  const [address,setAddress] = useState()
  const [pk,setPk] = useState()
  const [res,setRes] = useState()
  const code = "console.log('Code Mirror!');"



  const rubyCode = `    const axios = require('axios')
  const pass = "your_passphrase"
  axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
  .then(res=>{
      console.log(res.data)
  })`

    useEffect(()=>{
      let phrase = ''
      var passPh = generatePassPhrase(10)
      for(let i=0;i<passPh.length;i++){
        if(i===passPh.length-1){
          phrase += `${passPh[i]}`
        }
        else{
          phrase += `${passPh[i]} ${''}`
        }
      }
      setPhrase(phrase)
    },[])

    const tryit = ()=>{
      var dt = new Date()
      var myDate = Date.parse(dt);
      setTimeStamp(myDate)

        // const axios = require('axios')
        // const pass = "your_passphrase"
        // axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
        // .then(res=>{
        //     console.log(res.data)
        // })

      if(phrase){
        axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{
          pass:phrase,
    })
        .then(res=>{
          var encrypted = CryptoJS.AES.encrypt(res.data.PrivateKey, process.env.REACT_APP_encryptionSecretKey).toString();
          var bytes = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_encryptionSecretKey);
          var decryptedImportedPK = bytes.toString(CryptoJS.enc.Utf8);
          // console.log(decryptedImportedPK)
          // console.log(res,'data')
          sessionStorage.setItem('pk', encrypted);
          sessionStorage.setItem('address', res.data.Address);
          setAddress(res.data.Address)
          setPk(decryptedImportedPK)
          setRes(JSON.stringify(res,null,2))
          
          var address = res.data.Address
          if(address){
            setSuccess(true)
          }
          // console.log(address)
          // setTimeout(()=>{
          //   window.location.href = '/credentials'
          // },1000)
        })
      }
      // console.log(phrase)
      
    }
  
  return (
 
    <Layout>
      <div className="row">
        <div class='col-md-12 col-sm-12 m-0 rowBorder' >
            {/* <br/> */}
              <h3 className='boldhed' style={{fontSize:'3rem'}}>Welcome to SharkWeb</h3>
              {/* <p style={{color:'gray'}}>Last edited on December 15, 2022</p> */}
             

              <p class="mt-3">
              <h3 className='boldhed' style={{fontSize:'2rem'}}>1. Sign up to Nocode</h3>
                <p>Sign up for an account on the <Link to={'https://nocode.sharkweb.com/'} target='_blank'> Nocode</Link> website, enter your details, and select  <span style={{fontSize:'1.1rem',fontWeight:700}}> CREATE A FREE ACCOUNT</span> .</p>
                <p>To activate your account, verify your email address by clicking the link sent to your inbox.</p>
                </p>
              <p class="mt-3">
              <h3 className='boldhed' style={{fontSize:'2rem'}}>2. Create an API key</h3>
                <p>Once verified, you’ll be taken to the Nocode dashboard where you can create your API key.</p>
                <p>To authenticate your network requests, generate an API key. Click on<span style={{fontSize:'1.1rem',fontWeight:700}}> "Generate Api Keys" </span> to proceed.</p>
                </p>
              <img src={createapi} alt="" style={{width:'100%',height:'auto'}} />
              <p class="mt-3">Provide a name in the designated field and click on "CREATE" to proceed.</p>
              <img src={apipayment} alt="" style={{width:'100%',height:'auto'}} />

              <p  class="mt-3">After completing the payment process, you will be able to view your generated API key in the "API List" section.</p>
              {/* <p>In this example, we've selected the Sharkweb mainnet and Linea mainnet networks.</p> */}
              <img src={apilist} alt="" style={{width:'100%',height:'auto'}} />

              <h3 className='boldhed mt-3' style={{fontSize:'2rem'}}>3. Send requests</h3>
              <p>Utilize the generated API key when sending requests. The following examples demonstrate how to interact with the Sharkweb network by sending HTTPS requests.</p>
              <div style={{backgroundColor:'black',padding:'2%',borderRadius:'9px'}}>
              <p style={{fontWeight:700}}>  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>  INFO</p> 
              <ul>
                <li>All requests are POST requests.</li>
                <li>Replace YOUR-API-KEY with your own unique API key.</li>
              </ul>

              </div>

                <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Next steps to proceed:</p>
                <p>Firstly, ensure your hardware meets the required specifications. Subsequently, download and install SharkNode. Familiarize yourself with security considerations and ensure your firewall is properly configured</p>
                        
    {/* <br /> */}
    <p>If you're a newcomer to SharkNode, visit the <Link to={'/gettingstarted/introduction'} style={{textDecoration:'none'}}>"Getting Started"</Link>  page. This resource provides step-by-step guidance for new users, covering fundamental functions of SharkNode such as account creation, security measures, and initiating transactions.</p>
        <p>Next, we recommend delving into the material available in the Fundamentals section. These pages serve as a valuable resource for building a foundational understanding of how SharkNode operates both from a user perspective and under the hood.</p>
        <p>More advanced topics are also available - explore them using the sidebar!</p>
          
        {/* <br/> */}
      

          <hr style={{backgroundColor:'#2E2D34',marginTop:'25px',marginBottom:'25px'}}/>
                           
          {/* <p style={{float:'right',fontSize:'14px',cursor:'pointer',marginBottom:'0px'}}>GetBurmAIFI <img src={arrow} style={{height:'1.5vh',marginLeft:'5px'}}/></p> */}
            </div>
            </div>
            </Layout>
  
  )


}
export default Docs

