import './App.css';
import { BrowserRouter , Route, Routes } from "react-router-dom";
import Console from './components/Console'
import GetNode from './components/sharkwebapi/GetNode';
import GetActivity from './components/sharkwebapi/GetActivity';
import CheckBalance from './components/sharkwebapi/CheckBalance';
import CreateWallet from './components/sharkwebapi/CreateWallet';
import Tokenmint from './components/sharkwebapi/Tokenmint';
import Tokentransfer from './components/sharkwebapi/Tokentransfer';
import Nftdetails from './components/sharkwebapi/Nftdetails';
import Nfttransfer from './components/sharkwebapi/Nfttransfer';
import Docs from './components/Docs';
import Introduction from './components/gettingstarted/Introduction';
import Hardwarerequirement from './components/gettingstarted/Hardwarerequirement';
import Installingsharknode from './components/gettingstarted/Installingsharknode';
import Fundamental from './components/Fundamental';
import Resources from './components/Resources';
import Downloads from './components/Downloads';
import Fundamentals from './components/fundamentals/Fundamentals';
import Nodearchitecture from './components/fundamentals/Nodearchitecture';
import Security from './components/fundamentals/Security';
import Syncmodes from './components/fundamentals/Syncmodes';
import Databases from './components/fundamentals/Databases';
import Backupandrestore from './components/fundamentals/Backupandrestore';
import Connectingtopeers from './components/fundamentals/Connectingtopeers';
import Creatingadashboard from './components/monitoring/Creatingadashboard';
import Directsharknode from './components/monitoring/Directsharknode';
import Dapps from './components/sharkwebapi/Dapps';
import Tokencreate from './components/sharkwebapi/Tokencreate';
import Nftcreate from './components/sharkwebapi/Nftcreate';
function App() {

  return (  
  <BrowserRouter>
  <Routes>
 
  <Route path='/docs' element={<Docs/>}/>
  <Route path='/fundamentals' element={<Fundamental/>}/>  
  <Route path='/resources' element={<Resources/>}/>
  <Route path='/downloads' element={<Downloads/>}/>
  {/* <Route path='/' element={<Docs/>}/> */}
  <Route path='/' element={<CreateWallet/>}/>




  {/* Getting Started  */}
  <Route path='/gettingstarted/introduction' element={<Introduction/>}/>
  <Route path='/gettingstarted/hardwarerequirements' element={<Hardwarerequirement/>}/>
  <Route path='/gettingstarted/installingsharknode' element={<Installingsharknode/>}/>
 {/* Getting Started  */}

  {/* Fundamentals  */}
  <Route path='/fundamentals/introduction' element={<Fundamentals/>}/>
  <Route path='/fundamentals/nodearchitecture' element={<Nodearchitecture/>}/>
  <Route path='/fundamentals/security' element={<Security/>}/>
  <Route path='/fundamentals/syncmodes' element={<Syncmodes/>}/>
  <Route path='/fundamentals/databases' element={<Databases/>}/>
  <Route path='/fundamentals/backupandrestore' element={<Backupandrestore/>}/>
  <Route path='/fundamentals/connectingtopeers' element={<Connectingtopeers/>}/>
 {/* Fundamentals  */}

  {/* Monitoring  */}
  {/* <Route path='/monitoring/creatingadashboard' element={<Creatingadashboard/>}/>
  <Route path='/monitoring/directsharknode' element={<Directsharknode/>}/> */}
 {/* Monitoring  */}









 {/* Api */}
 <Route path='/sharkwebapi/getnode' element={<GetNode/>}/>
  <Route path='/sharkwebapi/getactivity' element={<GetActivity/>}/>
  <Route path='/sharkwebapi/checkbalance' element={<CheckBalance/>}/>
  <Route path='/sharkwebapi/createwallet' element={<CreateWallet/>}/>
  <Route path='/sharkwebapi/tokenmint' element={<Tokenmint/>}/>
  <Route path='/sharkwebapi/tokentransfer' element={<Tokentransfer/>}/>
  <Route path='/sharkwebapi/nftdetails' element={<Nftdetails/>}/>
  <Route path='/sharkwebapi/tokencreate' element={<Tokencreate/>}/>
  <Route path='/sharkwebapi/nftcreate' element={<Nftcreate/>}/>
  <Route path='/sharkwebapi/nfttransfer' element={<Nfttransfer/>}/>
  <Route path='/sharkwebapi/dapps' element={<Dapps/>}/>
 {/* Api */}
    </Routes>
  </BrowserRouter>

    );

  }

  export default App;
