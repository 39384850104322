import React, { useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import Logo from '../Images/Logo.png'


import '../css/style.css'
import { Link, useLocation } from 'react-router-dom';

const Header=()=>  {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  const [url2, setUrl2] = useState(null);
  const [open,setOpen]=useState(false)
  const [fundamental,setFundamental] = useState(false)
  const [monitoring,setMonitoring] = useState(false)
  const [api,setApi]=useState(false)
  const apilist = [
    {apiname:'Create Wallet',url:'createwallet',method:'Post'},
    {apiname:'GetNode info ',url:'getnode',method:'Get'},
    {apiname:'GetActivityDetails ',url:'getactivity',method:'Get'},
    {apiname:'Check Balance ',url:'checkbalance',method:'Get'},
    {apiname:'Token Create',url:'tokencreate',method:'Post'},
    {apiname:'Token mint',url:'tokenmint',method:'Post'},
    {apiname:'Token Transfer',url:'tokentransfer',method:'Post'},
    {apiname:'NFT Create',url:'nftcreate',method:'Post'},
    {apiname:' NFT Transfer',url:'nfttransfer',method:'Post'},
    {apiname:'NFT details',url:'nftdetails',method:'Post'},
    {apiname:'dApps',url:'dapps',method:'Post'},
  ]
  useEffect(() => {
    setUrl(location.pathname);
    const pathParts = location.pathname.split('/'); 
    const firstPart = pathParts[1];
    const secondPart = pathParts[2];
    setUrl(secondPart)  
    setUrl2(firstPart)
    if(firstPart === 'gettingstarted'){
      setOpen(true)
    }else{
      setOpen(false)
    }
    if(firstPart === 'fundamentals'){
      setFundamental(true)
    }else{
      setFundamental(false)
    }
    if(firstPart === 'monitoring'){
      setMonitoring(true)
    }else{
      setMonitoring(false)
    }
    if(firstPart==='sharkwebapi'){
      setApi(true)
    }else{
      setApi(false)
    }
  }, [location]);

  return (
<>
<nav class="navbar navbar-expand-lg navbar-dark plTab" style={{backgroundColor:'#13072A',paddingLeft:'5%'}}>
<a class="navbar-brand" href="#"><img class="logocls" style={{height:'7.5vh',marginTop:'2px'}} src={Logo}/></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{color:'white'}}>
 
    <ul class="navbar-nav mobile_view_only">
        <li class="nav-item">
        <a class="nav-link" href="#" style={{color:'white'}}>  AIFIGRID V1 API</a>
        </li>
        <li class="nav-item">
        <a class="nav-link" href="#" style={{color:'white'}}>   GetNode info </a>
        </li>
        <li class="nav-item">
        <a class="nav-link" href="#" style={{color:'white'}}>  GetBurnAifi</a>
        </li>
        <li class="nav-item">
        <a class="nav-link" href="#" style={{color:'white'}}> DEX Exchange</a>
        </li>

          <li class="nav-item">
        <a class="nav-link" href="#" style={{color:'white'}}>       Overview</a>
        </li>  <li class="nav-item">
        <a class="nav-link" href="#" style={{color:'white'}}>   Smart Contract</a>
        </li>
    </ul>
    <ul class="navbar-nav mr-auto">
      {/* <li style={{width:'12rem',float:'left'}}>
      <img src={Logo} style={{height:'7.5vh',marginTop:'2px'}}/>
      </li> */}



      <li class="nav-item activee">
        <a class="nav-link" href="#">
            <button class="btn btn-outline-success" onClick={()=>window.location.href='/docs'} style={{border:'1px solid white',color:'white',fontWeight:'600',marginRight:'20px'}}>Documentation</button> <span class="sr-only">(current)</span></a>
      </li>



      <li class="nav-item activee">
        <a class="nav-link" href="#">
            <button class="btn btn-outline-success" onClick={()=>window.location.href='/downloads'} style={{border:'1px solid white',color:'white',fontWeight:'600',marginRight:'20px'}}>DOWNLOADS</button> <span class="sr-only">(current)</span></a>
      </li>






      {/* 3f1585 */}
      {/* <li class="nav-item">
        <a class="nav-link Mheade10" href="/" style={{color:'white',marginTop:''}}>Compiler</a>
      </li> */}
    
    </ul>

      <div className='mobileViewAccord'>
      <ul className="nav nav-pills flex-column mb-auto">


{/* <div class="accordion" id="accordionExample">
<div class="accordion-item">
<h2 class="accordion-header" id="headingOne">
  <button class="accordion-button collapsed fntResp" style={{background:'transparent',color:'#fff',border:'none',width:'100%',textAlign:'start',fontSize:'1.2rem',fontWeight: url2 === 'gettingstarted' ? 800: ''}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
  Getting Started
  </button>
</h2>
<div id="collapseOne" class={"accordion-collapse "+(!open && 'collapse')} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
<li>
   <Link to='/gettingstarted/introduction'className={"nav-link mt-3 text-white"+(url === 'introduction'&& url2==='gettingstarted'?' active ' : '')} >
    Introduction
  </Link>
  </li>
  
  <li>
   <Link to='/gettingstarted/hardwarerequirements' className={"nav-link mt-3 text-white"+(url === 'hardwarerequirements'?' active ' : '')}> Hardware Requirements</Link>
  </li>
  <li>
   <Link to='/gettingstarted/installingsharknode' className={"nav-link mt-3 text-white"+(url === 'installingsharknode'?' active ' : '')}> Installing SharkNode</Link>
  </li>
</div>
</div>


</div>

<div class="accordion" id="accordionExample">
<div class="accordion-item">
<h2 class="accordion-header" id="headingTwo">

<button class="accordion-button collapsed fntResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"  style={{background:'transparent',color:'#fff',border:'none',width:'100%',textAlign:'start',fontSize:'1.2rem',fontWeight: url2 === 'fundamentals' ? 800: ''}}>
  Fundamentals
  </button>
 
</h2>
<div id="collapseTwo" class={"accordion-collapse "+(!fundamental && 'collapse')} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">

  <li>
   <Link to='/fundamentals/introduction'className={"nav-link mt-3 text-white"+(url === 'introduction'&& url2==='fundamentals'?' active ' : '')} >
    Introduction
  </Link>
  </li>

  <li>
   <Link to='/fundamentals/nodearchitecture' className={"nav-link mt-3 text-white"+(url === 'nodearchitecture'?' active ' : '')}>Node Architecture</Link>
  </li>
  <li>
   <Link to='/fundamentals/security' className={"nav-link mt-3 text-white"+(url === 'security'?' active ' : '')}>
    Security
  </Link>
  </li>
  <li>
   <Link to='/fundamentals/syncmodes' className={"nav-link mt-3 text-white"+(url === 'syncmodes'?' active ' : '')}>
    Sync-modes</Link>
  </li>
  <li>
   <Link to='/fundamentals/databases' className={"nav-link mt-3 text-white"+(url === 'databases'?' active ' : '')}>Databases</Link>
  </li>
  <li>
   <Link to='/fundamentals/backupandrestore' className={"nav-link mt-3 text-white"+(url === 'backupandrestore'?' active ' : '')}>Backup & restore</Link>
  </li>
  <li>
   <Link to='/fundamentals/connectingtopeers' className={"nav-link mt-3 text-white"+(url === 'connectingtopeers'?' active ' : '')}>Connecting to Peers</Link>
  </li>
</div>
</div>


</div>

<div class="accordion" id="accordionExample">
<div class="accordion-item">
<h2 class="accordion-header" id="headingThree">

<button class={"accordion-button collapsed fntResp"} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"  style={{background:'transparent',color:'#fff',border:'none',width:'100%',textAlign:'start',fontSize:'1.2rem',fontWeight: url2 === 'monitoring' ? 800: ''}}>
  Monitoring
  </button>
 
</h2>
<div id="collapseThree" class={"accordion-collapse "+(!monitoring && 'collapse')} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
<li>
  <Link to="/monitoring/creatingadashboard" className={"nav-link mt-3 text-white"+(url === 'creatingadashboard'?' active ' : '')}>
  Creating a Dashboard       
  </Link>
</li>
<li>
  <Link to="/monitoring/directsharknode" className={"nav-link mt-3 text-white"+(url === 'directsharknode'?' active ' : '')}>
  Direct SharkNode Status
  </Link>
</li>
</div>
</div>


</div> */}




{/* </li> */}
<div class="accordion" id="accordionExample">
<div class="accordion-item">
<h2 class="accordion-header" id="headingThree">

<button class="accordion-button collapsed fntResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"  style={{background:'transparent',color:'#fff',border:'none',width:'100%',textAlign:'start',fontSize:'1.2rem',fontWeight: url2 === 'sharkwebapi' ? 800: ''}}>
SHARKWEB API
  </button>
 
</h2>
<div id="collapseFour" class={"accordion-collapse "+(!api && 'collapse')} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">

  {apilist?.map((api,i)=>(
  <li key={i}>
    <Link to={`/sharkwebapi/${api?.url}`} className={"nav-link mt-3 text-white"+(url === api?.url ?' active ' : '')} aria-current="page">
      {api.apiname}
      <span className='getbtn' style={{marginRight:'',float:'right'}}>{api?.method}</span>
    </Link>
  </li>
  ))}

</div>
</div>


</div>



</ul>
{/* <ul className="nav-item" style={{padding:'0.5rem',borderRadius:'.2rem',margin:'0 0.5rem'}}>
<Link to={'/resources'}>     <button style={{background:'transparent',color:'#fff',border:'none',width:'100%',textAlign:'start',fontSize:'1.2rem',fontWeight: url === 'resources' ? 800: ''}}>
Resources
  </button></Link>
</ul> */}
    </div>
   
  </div>
</nav>

</>
  )
    
}

export default Header;