import React, { useEffect, useRef, useState } from "react";
import { Terminal } from "xterm";
import "xterm/css/xterm.css";
import navTerm from "../Images/navterm.png";
import axios from "axios";
import CryptoJS from "crypto-js";
import { FitAddon } from "xterm-addon-fit";

function Terminal_() {
  const terminalRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [popstate, setPopstate] = useState([]);
  const [estate, setEstate] = useState('');
  const fitAddon = new FitAddon();

  useEffect(() => {
    const term = new Terminal();
    term.open(terminalRef.current);
    // Attach the FitAddon to the terminal
    term.loadAddon(fitAddon);
    fitAddon.fit();

    // console.log(fitAddon,'fitAddon')

    // Initialize a WebSocket connection to the backend server
    const systemName = window.navigator.platform;
    term.write(`Hello from \x1B[1;3;31msharkweb\x1B[0m on ${systemName} $ `);

    const ws = new WebSocket("ws://your-backend-server-url");

    ws.onopen = () => {
      term.writeln("Connected to the server.");
      setSocket(ws);

      // Fetch and display the system's name
      const systemName = window.navigator.platform;
      term.write(`Hello from \x1B[1;3;31mxterm.js\x1B[0m on ${systemName} $ `);
    };

    ws.onmessage = (event) => {
      term.writeln(event.data);
    };

    ws.onerror = (error) => {
      term.writeln(`WebSocket Error: ${error}`);
    };

    ws.onclose = () => {
      term.writeln("Connection closed.");
    };

    ws.WrongCmd = () => {
      term.writeln("\n\rInvalid Command");
    };

    // Add event listener to capture keyboard input
    term.onData((e) => {
      
      if (e === "\r") {
        // Handle Enter key (send command to server)
        const command = inputBuffer.trim();

// --------- Api integration starts here ----------

        if (command.startsWith("createWallet")) {
          const parts = command.split(" ");
          if (parts.length >= 2) {
            const pass = parts.slice(1).join(" ");
            if (pass.length <= 90 && pass.length >= 75) {
              // console.log(pass, "pass");
              term.writeln(
                `\r\n\r\nCreating wallet with passphrase: \r\n` + pass
              );
              axios
                .post(
                  "https://testnet.sharkweb.com/register-and-broadcast-address",
                  { pass: pass }
                )
                .then((res) => {
                  var encrypted = CryptoJS.AES.encrypt(
                    res.data.PrivateKey,
                    process.env.REACT_APP_encryptionSecretKey
                  ).toString();
                  var bytes = CryptoJS.AES.decrypt(
                    encrypted,
                    process.env.REACT_APP_encryptionSecretKey
                  );
                  var decryptedImportedPK = bytes.toString(CryptoJS.enc.Utf8);
                  let resp = JSON.stringify(res);
                  term.writeln(`\n\r Wallet Created Successfully!\r\n`);
                  setTimeout(() => {
                    term.writeln(
                      `\r Address:${res.data.Address}\r\n PrivateKey:${res.data.PrivateKey}`
                    );
                  }, 2000);
                });
            } else {
              ws.WrongCmd();
            }
          } else {
          }
        } else if (command === "getnode") {
          axios
            .get("https://testnet.sharkweb.com/api/network/status")
            .then((res) => {
              term.write(
                `\r\nblockHeight: ${res.data.blockHeight}\r\n network: ${res.data.network}\r\n nodes: ${res.data.nodes}`
              );
            });
        } else if (command.startsWith("checkbalance")) {
          const parts = command.split(" ");
          if (parts.length >= 2) {
            const address = parts[1];
            if (address.length <= 40 && address.length >= 30) {
              term.writeln(
                `\r\n\r\Checking balance of address: \r\n` + address
              );

              axios
                .get(`https://testnet.sharkweb.com/address/${address}`)
                .then((res) => {
                  // alert('hii')
                  if (res.data.addressData.returnVal) {
                    term.writeln(`\n\r Balance fetched Successfully!\r\n`);
                    setTimeout(() => {
                      term.writeln(
                        `\r AddressBalance:${Math.floor(
                          res.data.addressData.returnVal.addressBalance
                        )}`
                      );
                    }, 2000);
                  }
                  // console.log(res);
                });
            } else {
              ws.WrongCmd();
            }
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("getactivity")) {
          const parts = command.split(" ");
          if (parts.length >= 2) {
            const address = parts[1];
            if (address.length <= 40 && address.length >= 30) {
              term.writeln(
                `\r\n\r\Checking balance of address: \r\n` + address
              );

              axios
                .get(`https://testnet.sharkweb.com/address/${address}`)
                .then((res) => {
                  const myArr =
                    res.data.addressData.returnVal.addressTransactions;
                  const lastIndex = myArr[myArr.length - 1];
                  // console.log(lastIndex, "lastIndex");

                  if (res.data.addressData.returnVal) {
                    term.writeln(
                      `\n\r Last Activity fetched Successfully!\r\n`
                    );
                    setTimeout(() => {
                      term.writeln(`\r Last Transaction:`);
                      term.writeln(`\r Amount:${lastIndex.amount}`);
                      term.writeln(`\r Amount:${lastIndex.recipient}`);
                      term.writeln(`\r Amount:${lastIndex.remark}`);
                      term.writeln(`\r Amount:${lastIndex.sender}`);
                      term.writeln(`\r Amount:${lastIndex.timestamp}`);
                      term.writeln(`\r Amount:${lastIndex.transactionId}`);
                    }, 2000);
                  }
                  // console.log(res);
                });
            } else {
              ws.WrongCmd();
            }
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("nativeCoinTransaction")) {
          const parts = command.split(" ");
          if (parts.length >= 3) {
            const amount = parts[1];
            const recipient = parts[2];
            const pk = parts[3];
            const pass = parts.slice(4).join("");

            term.writeln(
              `\r\n\r\nYour Native Coin Transaction details :- \r\n`
            );
            term.writeln(`\ramount : ${amount}`);
            term.writeln(`\rrecipient : ${recipient}`);
            term.writeln(`\rpk : ${pk}`);
            term.writeln(`\rpaas : ${pass}`);
            axios
              .post("https://testnet.sharkweb.com/transaction/broadcast", {
                privatekey: pk,
                pass: pass,
                recipient: recipient,
                amount: amount,
                remark: "AIFI",
              })
              .then((res) => {
                term.writeln(`\r\nYour Transaction is in Process`);
                // console.log(res, "response");
                term.writeln(`Your Native Coin Transaction Result :- \r\n`);
                term.writeln(`Result : ${res.data} `);
              });
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("tokenTransfer")) {
          const parts = command.split(" ");
          if (parts.length >= 3) {
            const amount = parts[1];
            const recipient = parts[2];
            const remark = parts[3];
            const pk = parts[4];
            const pass = parts.slice(5).join("");

            // console.log(remark);

            term.writeln(`\r\n\r\nYour tokenTransfer details :- \r\n`);
            term.writeln(`\r amount : ${amount}`);
            term.writeln(`\r recipient : ${recipient}`);
            term.writeln(`\r Pk : ${pk}`);
            term.writeln(`\r Passphrase : ${pass}`);
            term.writeln(`\r remark : ${remark}`);
            axios
              .post("https://testnet.sharkweb.com/transaction/broadcast", {
                privatekey: pk,
                pass: pass,
                recipient: recipient,
                amount: amount,
                remark: remark,
              })
              .then((res) => {
                term.writeln(`\r\nYour tokenTransfer is in Process`);
                // console.log(res, "response");
                term.writeln(`\r\n Your Token Transaction Result :- \r\n`);
                term.writeln(`Result : ${res.data} `);
              });
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("dataTransaction")) {
          const parts = command.split(" ");
          if (parts.length >= 3) {
            const fname = parts[1];
            const lname = parts[2];
            const mob = parts[3];
            const state = parts[4];
            const city = parts[5];
            const dt_of_addmission = parts[6];
            const password = parts[7];
            const pk = parts[8];
            const data = {
              first_name: fname,
              last_name: lname,
              mob: mob,
              state: state,
              city: city,
              date_of_admission: dt_of_addmission,
            };

            // console.log( fname,"\n",lname,"\n",mob,"\n",state,"\n",city,"\n",dt_of_addmission,"\n",password,"\n", pk,"\n",data,"data"  );
            term.writeln(`\r\n\r\nYour dataTransaction details :- \r\n`);
            term.writeln(`\r Firstname : ${fname}`);
            term.writeln(`\r Lastname : ${lname}`);
            term.writeln(`\r mob : ${mob}`);
            term.writeln(`\r state : ${state}`);
            term.writeln(`\r city : ${city}`);
            term.writeln(`\r date of Addmission : ${dt_of_addmission}`);
            term.writeln(`\r password : ${password}`);
            term.writeln(`\r Private Key : ${pk}`);
            axios
              .post("https://testnet.sharkweb.com/transaction/broadcast", {
                privatekey: pk,
                pass: password,
                data: data,
              })
              .then((res) => {
                term.writeln(`\r\nYour Data Transaction is in Process`);
                // console.log(res, "response");
                term.writeln(`\r\n Your Data Transaction Result :- \r\n`);
                term.writeln(`Result : ${res.data} `);
              });
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("createToken")) {
          const parts = command.split(" ");
          if (parts.length >= 3) {
            const tokenName = parts[1];
            const decimal = parts[2];
            const tokenSymbol = parts[3];
            const pk = parts[4];
            const pass = parts.slice(5).join("");

            term.writeln(`\r\n\r\nYour tokenTransfer details :- \r\n`);
            term.writeln(`\r tokenName : ${tokenName}`);
            term.writeln(`\r decimal : ${decimal}`);
            term.writeln(`\r tokenSymbol : ${tokenSymbol}`);
            term.writeln(`\r Privatekey : ${pk}`);
            term.writeln(`\r Passphrase : ${pass}`);

            axios
              .post("https://testnet.sharkweb.com/createToken", {
                tokenName: tokenName,
                decimal: decimal,
                privatekey: pk,
                pass: pass,
                tokenSymbol: tokenSymbol,
              })
              .then((res) => {
                term.writeln(`\r\nYour Token Creation is in Process`);
                // console.log(res, "response");
                term.writeln(`\r\n Your Token Transaction Result :- \r\n`);
                term.writeln(`Result : ${res.data} `);
              });
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("miningToken")) {
          const parts = command.split(" ");
          if (parts.length >= 3) {
            const amount = parts[1];
            const remark = parts[2];
            const sender = parts[3];
            const pk = parts[4];
            const pass = parts.slice(5).join("");

            term.writeln(`\r\n\r\nYour tokenTransfer details :- \r\n`);
            term.writeln(`\r amount : ${amount}`);
            term.writeln(`\r remark : ${remark}`);
            term.writeln(`\r sender : ${sender}`);
            term.writeln(`\r Privatekey : ${pk}`);
            term.writeln(`\r Passphrase : ${pass}`);

            axios
              .post("https://testnet.sharkweb.com/transaction/broadcast", {
                amount: amount,
                remark: remark,
                privatekey: pk,
                pass: pass,
                sender: sender,
              })
              .then((res) => {
                term.writeln(`\r\nYour Token mining is in Process`);
                // console.log(res, "response");
                term.writeln(`\r\n Your Token Mining Result :- \r\n`);
                term.writeln(`Result : ${res.data} `);
              });
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("nftownershiptransfer")) {
          const parts = command.split(" ");
          if (parts.length >= 3) {
            const privatekey = parts[1];
            const pass = parts[2];
            const transactionsID = parts[3];
            const fileHash = parts[4];
            const status = parts[5];
            const transferPrice = parts[6];
            const newOwnerAddress = parts[7];

            term.writeln(`\r\n\r\nYour tokenTransfer details :- \r\n`);
            term.writeln(`\r privatekey : ${privatekey}`);
            term.writeln(`\r pass : ${pass}`);
            term.writeln(`\r transactionsID : ${transactionsID}`);
            term.writeln(`\r fileHash : ${fileHash}`);
            term.writeln(`\r status : ${status}`);
            term.writeln(`\r transferPrice : ${transferPrice}`);
            term.writeln(`\r newOwnerAddress : ${newOwnerAddress}`);

            axios
              .post("https://testnet.sharkweb.com/ownership", {
                privatekey: privatekey,
                pass: pass,
                transactionsID: transactionsID,
                fileHash: fileHash,
                status: status,
                transferPrice: transferPrice,
                newOwnerAddress: newOwnerAddress,
              })
              .then((res) => {
                term.writeln(`\r\nYour Token Creation is in Process`);
                // console.log(res, "response");
                term.writeln(`\r\n Your Token Transaction Result :- \r\n`);
                term.writeln(`Result : ${res.data} `);
              });
          } else {
            ws.WrongCmd();
          }
        } else if (command.startsWith("nftcreation")) {
          const parts = command.split(" ");
          if (parts.length >= 3) {
            const privatekey = parts[1];
            const pass = parts[2];
            const minValue = parts[3];
            const fileHash = parts[4];
            const status = parts[5];
            term.writeln(`\r\n\r\nYour NFT creation details :- \r\n`);
            term.writeln(`\r privatekey : ${privatekey}`);
            term.writeln(`\r pass : ${pass}`);
            term.writeln(`\r minValue : ${minValue}`);
            term.writeln(`\r fileHash : ${fileHash}`);
            term.writeln(`\r status : ${status}`);

            axios
              .post("https://testnet.sharkweb.com/createNFT", {
                privatekey: privatekey,
                pass: pass,
                minValue: minValue,
                fileHash: fileHash,
                status: status,
              })
              .then((res) => {
                term.writeln(`\r\nYour NFT is in Process`);
                // console.log(res, "response");
                term.writeln(`\r\n Your NFT creation Result :- \r\n`);
                term.writeln(`Result : ${res.data} `);
              });
          } else {
            ws.WrongCmd();
          }
        } else if (command === "clear") {
          term.clear();
        } else if (command === "--help") {
          term.writeln(`\r\n\r\n Commands Execution :-`);
          term.writeln(
            `\r\n createWallet <Paasphrase> (Create New Aifi Wallet) `
          );
          term.writeln(
            `\r\n checkbalance <address>    (Check Balance in your Wallet) `
          );
          term.writeln(
            `\r\n getactivity <address>     (Check Last Activity of your Wallet address) `
          );
          term.writeln(
            `\r\n nativeCoinTransaction <amount> <recipient> <privatekey> <passphrase> (native coin transaction)`
          );
          term.writeln(
            `\r\n tokenTransfer <amount> <recipient> <remark> <privatekey> <passphrase> (token transfer)`
          );
          term.writeln(
            `\r\n dataTransaction <fname> <lname> <mob> <state> <city> <date_of_addmission> <password> <privatekey> (dataTransaction)`
          );
          term.writeln(
            `\r\n createToken <tokenName> <decimal> <tokenSymbol> <privatekey> <passphrase> (token Creation)`
          );
          term.writeln(
            `\r\n miningToken <amount> <remark> <sender> <privatekey> <passphrase> (token Mining)`
          );
          term.writeln(
            `\r\n nftownershiptransfer <privatekey> <pass> <transactionsID> <fileHash> <status> <transferPrice> <newOwnerAddress>  (nft Ownershil Transfer)`
          );
          term.writeln(
            `\r nftcreation <privatekey> <pass> <minValue> <fileHash> <status> (nft Creation)`
          );
        } else {
          ws.WrongCmd();
        }
// --------- Api integration ends here ----------



        if (socket && command) {
          socket.send(command);
        }
        inputBuffer = ""; // Clear the input buffer
        term.write("\r\n$ "); // Display the command prompt
      } else if (e === "\x08") {
        // Handle Backspace key
        handleBackspace();

      } else if (e === "\x7F") {
        // console.log(popstate,'popstate')
        if(popstate.length > 1){
          // alert('hey')
          // console.log(popstate)
          popstate.pop()
        }
       
        // Handle Backspace key
        handleBackspace();
      } else {
        // Normal character input
        inputBuffer += e;
        term.write(e);
      }
    });


    function handleBackspace() {
      const cursorX = term.buffer.x;
      const cursorY = term.buffer.y;
      if (cursorX === 0 && cursorY > 0) {
        term.buffer.x = term.cols - 1;
        term.buffer.y--;
        term.buffer.lines.get(term.buffer.y).loadCell(term.buffer.x, term.blankCell);
      } else {
        term.write('\b \b');
      }
    }

    // Cleanup when the component unmounts
    return () => {
      term.dispose();
      if (socket) {
        socket.close();
      }
    };
  }, []);

  const [isMaximized, setIsMaximized] = useState(false);

  const handleMinimize = () => {
    // Add code to minimize the terminal window
  };

  const handleMaximize = () => {
    // Add code to maximize the terminal window
    setIsMaximized(!isMaximized);
  };

  const handleClose = () => {
    // Add code to close the terminal window
  };

  let inputBuffer = "";

  return (
    <>
      <div
        style={{
          padding: "1%",
          backgroundColor: "white",
          background: `url(${navTerm}`,
          height: "3rem",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>

      <div className={""} style={{ width: "100%", height: "auto" }}>
        <div className="top-bar">
          <div className="button minimize" onClick={handleMinimize}></div>
          <div className="button maximize" onClick={handleMaximize}></div>
          <div className="button close" onClick={handleClose}></div>
        </div>
        <div ref={terminalRef} className="terminal"></div>
      </div>
    </>
  );
}

export default Terminal_;
