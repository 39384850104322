import React, { Component, useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../css/style.css'
import expand from '../Images/expand.png'
import arrow from '../Images/arrow.png'
import refresh from '../Images/refresh.png'
import shell from '../Images/shell.png'
import node from '../Images/node.png'
import python from '../Images/python.png'
import ruby from '../Images/ruby.png'
import php from '../Images/php.png'
import { Link } from 'react-router-dom';
import GetSidebar from './GetSidebar';
import Header from './Header';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import Footer from './Footer';
import mac from '../Images/mac.png'
import windows from '../Images/windows.png'
import linux from '../Images/linux.png'
import resources from '../Images/resources.png'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Downloads.css'
import Layout from './Layout/Layout';

const Downloads =()=> {
  const [phrase,setPhrase] = useState();
  const [success,setSuccess] = useState();
  const [timestamp,setTimeStamp] = useState()
  const [address,setAddress] = useState()
  const [pk,setPk] = useState()
  const [res,setRes] = useState()
  const code = "console.log('Code Mirror!');"



  const rubyCode = `    const axios = require('axios')
  const pass = "your_passphrase"
  axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
  .then(res=>{
      console.log(res.data)
  })`

    useEffect(()=>{
      let phrase = ''
      var passPh = generatePassPhrase(10)
      for(let i=0;i<passPh.length;i++){
        if(i===passPh.length-1){
          phrase += `${passPh[i]}`
        }
        else{
          phrase += `${passPh[i]} ${''}`
        }
      }
      setPhrase(phrase)
    },[])

    const tryit = ()=>{
      var dt = new Date()
      var myDate = Date.parse(dt);
      setTimeStamp(myDate)

        // const axios = require('axios')
        // const pass = "your_passphrase"
        // axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
        // .then(res=>{
        //     console.log(res.data)
        // })

      if(phrase){
        axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{
          pass:phrase,
    })
        .then(res=>{
          var encrypted = CryptoJS.AES.encrypt(res.data.PrivateKey, process.env.REACT_APP_encryptionSecretKey).toString();
          var bytes = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_encryptionSecretKey);
          var decryptedImportedPK = bytes.toString(CryptoJS.enc.Utf8);
          // console.log(decryptedImportedPK)
          // console.log(res,'data')
          sessionStorage.setItem('pk', encrypted);
          sessionStorage.setItem('address', res.data.Address);
          setAddress(res.data.Address)
          setPk(decryptedImportedPK)
          setRes(JSON.stringify(res,null,2))
          
          var address = res.data.Address
          if(address){
            setSuccess(true)
          }
          // console.log(address)
          // setTimeout(()=>{
          //   window.location.href = '/credentials'
          // },1000)
        })
      }
      // console.log(phrase)
      
    }
  
  return (
    <Layout>
        <div className="row">
        <div class='col-md-12 col-sm-12 m-0 rowBorder' >
            {/* <br/> */}
              <h3 className='boldhed'>Download SharkNode</h3>
              <p>SharkNode (v1.0)</p>
             <p>
             Welcome to Sharknode, the cutting-edge blockchain client for Sharkweb. Below, you can find the latest stable release of Sharknode for 64-bit systems on our primary platforms. For all supported platforms and development builds, please scroll down the page. If you prefer installing Sharknode and its associated tools using your favorite package manager, consult our installation guide for detailed instructions.
             </p>                                           
             <p>Download the latest stable release for your platform and experience the power of Sharknode in managing the blockchain for Sharkweb.</p>    
               
              <br/>
                <div class="table-responsive " style={{marginTop:''}}>
                <table class="table tableme table-dark table-dark2 table-bordered mb-0 downloadTble" style={{padding:'',width:'90%',background:'transparent'}}>
              <thead class="" style={{borderBottom:''}}>
              <tr className='mltr2'>
                  <th scope="col" className='btn-outline-success1' style={{backgroundColor:'#3f1585','&: hover':{backgroundColor:'#fff'} ,color:'#fff',fontWeight:'700',fontSize:'1rem'}}>
                  <Link to={'https://downloads.sharkweb.com/Linux.zip' } target='_blank'  style={{textDecoration:'none',color:'#fff'}}>

                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:4,padding:'0.7rem 0'}}>
                    <i class="fab fa-linux" style={{fontSize:'2rem'}} ></i>
                    <p style={{alignItems:'center',fontSize:'1rem',margin:0}}>Linux</p>

                    </div>
                    </Link>

                     </th>
                     <th scope="col" className='btn-outline-success1' style={{backgroundColor:'#3f1585',color:'#fff',fontWeight:'700',fontSize:'1rem'}}>
                     <Link to={'https://downloads.sharkweb.com/Windows.zip' } target='_blank'  style={{textDecoration:'none',color:'#fff'}}>

                    
                  <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:4,padding:'0.7rem 0'}}>
                  <i class="fab fa-windows" style={{fontSize:'2rem'}}></i>
                                      <p style={{alignItems:'center',fontSize:'1rem',margin:0}}>Windows</p>

                    </div>
                    </Link>
                  </th>
                  <th scope="col" className='btn-outline-success1' style={{backgroundColor:'#3f1585',color:'#fff',fontWeight:'700',fontSize:'1rem'}}>
                  <Link to={'https://downloads.sharkweb.com/mac.zip' } target='_blank'  style={{textDecoration:'none',color:'#fff'}}>                  
                  <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:4,padding:'0.7rem 0'}}>
                  <i class="fab fa-apple" style={{fontSize:'2rem'}} ></i>
                    <p style={{alignItems:'center',fontSize:'1rem',margin:0}}>Mac</p>
                    </div>
                    </Link>
                  </th>
                 
                </tr>
               
              </thead>
              <thead class="" style={{borderBottom:''}}>
              <tr className='mltr2'>
                
                 
                </tr>
               
              </thead>
       
                </table>    
                <br />
                {/* <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Home">
        Tab content for Home
      </Tab>
      <Tab eventKey="profile" title="Profile">
        Tab content for Profile
      </Tab>
      <Tab eventKey="contact" title="Contact" disabled>
        Tab content for Contact
      </Tab>
    </Tabs> */}
                    
                           </div>
                           <br />

                
      
          
        <br/>
          <hr style={{backgroundColor:'#2E2D34',marginTop:'25px',marginBottom:'25px'}}/>
                           
          {/* <p style={{float:'right',fontSize:'14px',cursor:'pointer',marginBottom:'0px'}}>GetBurmAIFI <img src={arrow} style={{height:'1.5vh',marginLeft:'5px'}}/></p> */}
            </div>
            </div>
            </Layout>
 
  )


}
export default Downloads

