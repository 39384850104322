import React, { Component, useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../../css/style.css'
import expand from '../../Images/expand.png'
import arrow from '../../Images/arrow.png'
import refresh from '../../Images/refresh.png'
import shell from '../../Images/shell.png'
import node from '../../Images/node.png'
import python from '../../Images/python.png'
import ruby from '../../Images/ruby.png'
import php from '../../Images/php.png'
import { Link } from 'react-router-dom';
import GetSidebar from '../GetSidebar';
import Header from '../Header';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import Footer from '../Footer';
import ss from '../../Images/ss1.png'
import Layout from '../Layout/Layout';


const Hardwarerequirement =()=> {
  const [phrase,setPhrase] = useState();
  const [success,setSuccess] = useState();
  const [timestamp,setTimeStamp] = useState()
  const [address,setAddress] = useState()
  const [pk,setPk] = useState()
  const [res,setRes] = useState()
  const code = "console.log('Code Mirror!');"




  return (

        <Layout>
        <div className="row">
        <div class='col-md-12 col-sm-12 m-0 rowBorder' >
            {/* <br/> */}
              <h3 className='boldhed' style={{fontSize:'3rem'}}>Hardware requirements</h3>
              <p style={{color:'gray'}}>Last edited on September 14, 2023</p>
              <p class="mt-3">
                <p>The hardware requirements for running a SharkNode depend upon the node configuration and can change over time as upgrades to the network are implemented. Sharkweb nodes can be run on low power, resource-constrained devices such as Raspberry Pi's. Prebuilt, dedicated staking machines are available from several companies - these might be good choices for users who want plug-and-play hardware specifically designed for Sharkweb. However, many users will choose to run nodes on laptop or desktop computers.</p>
              </p>  {/* <br /> */}
              
               
                <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Processor   </p>
                <p>It is preferable to use a quad-core (or dual-core hyperthreaded) CPU. SharkNode is released for a wide range of architectures.</p>
            
                <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Memory    </p>
                <p>It is recommended to use at least 16GB RAM.</p>
              
                <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Disk space     </p>
                <p>Disk space is usually the primary bottleneck for node operators. At the time of writing (September 2022) a 2TB SSD is recommended for a full node running SharkNode and a consensus client. SharkNode itself requires 650GB of disk space for a snap-synced full node and, with the default cache size, grows about 14GB/week. Pruning brings the total storage back down to the original 650GB. Archive nodes require additional space. A "full" archive node that keeps all state back to genesis requires more than 12TB of space. Partial archive nodes can also be created by turning off the garbage collector after some initial sync - the storage requirement depends how much state is saved.</p>
                <p>As well as storage capacity, SharkNode nodes rely on fast read and write operations. This means HDDs and cheaper SSDs can sometimes struggle to sync the blockchain. A list of SSD models that users report being able and unable to sync SharkNode is available in this GitHub Gist. Please note that the list has not been verified by the SharkNode team.</p>


                <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Bandwidth</p>
                <p>It is important to have a stable and reliable internet connection, especially for running a validator because downtime can result in missed rewards or penalties. It is recommended to have at least 25Mbps download speed to run a node. Running a node also requires a lot of data to be uploaded and downloaded so it is better to use an ISP that does not have a capped data allowance.</p>

          

          {/* <p style={{float:'right',fontSize:'14px',cursor:'pointer',marginBottom:'0px'}}>GetBurmAIFI <img src={arrow} style={{height:'1.5vh',marginLeft:'5px'}}/></p> */}
            </div>
            </div>
            </Layout>

  )


}
export default Hardwarerequirement

