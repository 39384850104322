import React, { Component } from 'react'
import logo from '../Images/Logo.png'
import { Link } from 'react-router-dom';
// import '../../css/style.css'
import './Footer.css'
import Instagram from '../Images/Instagram.png'
import Twitter from '../Images/Twitter.png'
import teligram from '../Images/Telegram.png'
import discord from '../Images/Discord.png'

export default class Footer extends Component {
    render() {
        return (
            <>
            <div className='Foott'>
                <section className="d-flex justify-content-between p-0 text-white abc">
                    <div class="me-5">
                    <span><img class="Footer-Logo" src={logo}/></span>
                    </div>
                    <div className='d-flex abc' style={{display:'flex',justifyContent:'space-between',gap:'1rem'}}>
                        <a href="https://sharkweb.com/" target="_blank" style={{textDecoration:"none",color:"#fff"}} className="text-white me-4">
                        <p>SharkWeb</p>
                        </a>
                        <a href="https://sharkweb.com/files/privacy_policy.pdf" target="_blank" style={{textDecoration:"none",color:"#fff"}} class="text-white me-4">
                        <p>Privacy Policy</p>
                        </a>
                        <a href="https://sharkweb.com/files/terms_of_use.pdf" target="_blank" style={{textDecoration:"none",color:"#fff"}} class="text-white me-4">
                        <p>Terms of Services</p>
                        </a>
                        <a href="" class="text-white me-1" style={{textDecoration:"none",color:"#fff"}}>
                        <p>Join us :</p>
                        </a>
                        <div style={{display:'flex',gap:8}}>
                        <a href="https://twitter.com/sharkwebchain" target="_blank" class="text-white">
                        <img class="MediaHeight" src={Twitter} style={{height:'4vh',float:'',cursor:'pointer'}}/>
                        </a>
                        <a href="https://www.instagram.com/sharkwebchain/" target="_blank" class="text-white me-2">
                        <img class="MediaHeight" src={Instagram} style={{height:'4vh',float:'',marginRight:'',cursor:'pointer'}}/>
                        </a>
                        <a href="https://t.me/sharkwebchat" target="_blank" class="text-white">
                        <img class="MediaHeight" src={teligram} style={{height:'4vh',float:'',cursor:'pointer'}}/>
                        </a>
                        <a href="https://discord.com/invite/VtgkPRef53" target="_blank" class="text-white me-4">
                        <img class="MediaHeight" src={discord} style={{height:'4vh',float:'',marginRight:'',cursor:'pointer'}}/>
                        </a>
                        </div>
                        
                    </div>
                </section>
            </div>
            </>
        )
    }
}