import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../../css/style.css'
import GetSidebar from '../GetSidebar'
import bgnew from '../../Images/bgnew.png'
import guideimg from '../../Images/guide.png'
import './Layout.css'

const Layout = ({children}) => {
  return (
    <div>
            <div className="bgGetNode" >
            <Header/>
            <div class="row w-100 m-0">
                <div class='col-md-3 col-sm-12 p-0'>
                    <GetSidebar/>          
                </div>
                <div className="col-md-9" style={{borderLeft:'1px solid #32383e',marginTop:'2.5rem'}}>

          <div style={{padding:'0 1rem 1rem 1rem'}}>
          <div  className='bg1' >
            <div className="row">
              <div className="col-8 ">
                <div className='head-d1 justify-content-center align-items-center'>
                    <h1 className='textHead'>Developers Guide</h1>
                </div>

              </div>
                <div className="col-4  d-flex justify-content-center align-items-center">
                    <img src={guideimg} className='guideImg' alt="" />
                </div>
            </div>
            </div>
          </div>

            {children}
            
            </div>
            </div>
            <Footer/>
            </div>
    </div>
  )
}

export default Layout