import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Logo from '../Images/Logo.png'
import console from '../Images/console.png'
import navTerm from '../Images/navterm.png'


import Terminal_ from './Terminal_';

import { Terminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import Header from './Header';
import Footer from './Footer';

const terminal = new Terminal();
// Load WebLinksAddon on terminal, this is all that's needed to get web links
// working in the terminal.
terminal.loadAddon(new WebLinksAddon());


export default class Console extends Component {
    // componentDidMount(){
    //     const term = new Terminal();
    //     term.open(this.terminalRef.current);
    
    //     // Initialize a WebSocket connection to the backend server
    //     const systemName = window.navigator.platform;
    //     term.write(`Hello from \x1B[1;3;31msharkweb\x1B[0m on ${systemName} $ `);
    //   }

    render() {
        return (
            <>
            <Header/>
            <div className='profile2' style={{backgroundColor:'black'}}>
                <div class="row w-100 m-0" >
                    <div class="col-md-6 col-sm-12 p-0" >
                        <div class="profile" style={{height:'100vh',minWidth:'',overflowY:'scroll',padding:'10%'}}>
                        {/* <img src={Logo} style={{height:'8vh',marginTop:''}}/> */}
                        {/* <br/> <br/> */}
                        <h1 style={{fontSize:'33px',fontWeight:'600'}}>Generate Token in 
                        few Steps:</h1>
                        <p style={{fontSize:'13px'}}>Using the terminal (also known as command line or
                        shell) on a Unix-like operating system (such as
                        Linux or macOS) is a powerful way to interact with
                        your computer. Here's a step-by-step guide on how
                        to use the terminal:</p>
                        {/* <br/> */}
                        <ol>
                            <li style={{color:'#5c90fb',fontSize:'13px',fontWeight:'500'}}>
                                <p>Open the Terminal:</p>
                            
                                <ul  style={{color:'white',fontSize:'',fontWeight:'400'}}>
                                    <li> On macOS, you can find the Terminal application in the Utilities folder within the Applications folder. </li>
                                    <br/>
                                    <li>On Linux, you can usually access the terminal by pressing Ctrl+Alt+T or searching for "Terminal” in your application menu.
</li>
                                </ul>
                            </li>
                            <br/>
                            <li style={{color:'#5c90fb',fontSize:'13px',fontWeight:'500'}}>
                                <p>Understanding the Prompt:</p>
                                <p style={{color:'white',fontSize:'',fontWeight:'400'}}>When you open the terminal, you'll see a prompt. It
                                    usually consists of your username, the name of
                                    your computer, and the current directory.</p>
                            </li>
                            {/* <br/> */}
                            <li style={{color:'#5c90fb',fontSize:'13px',fontWeight:'500'}}>
                                <p> Navigating Directories: </p>
                            
                                <ul  style={{color:'white',fontSize:'',fontWeight:'400'}}>
                                    <li> To list the contents of the current directory, use the Is command: Is </li>
                                    <br/>
                                    <li>To change to a different directory, use the cd command followed by the directory path: cd [ path/to/directory </li>
                                    <br/>
                                    <li> Use cd .. to go up one directory and cd to go back to your home directory. </li>
                                </ul>
                            </li>
                        </ol>
                        </div>
                    
                    </div>
                    <div class="col-md-6 col-sm-12 p-0 respTer" style={{backgroundColor:'black',margin:'0.6rem 0rem',height:'70vh'}}>
                        
                        <Terminal_/>
                          
             
                    </div>
                </div>
           
                      
            </div>
            <Footer/>
          
            </>
        )
    }
}