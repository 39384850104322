import React, { Component, useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../../css/style.css'
import expand from '../../Images/expand.png'
import arrow from '../../Images/arrow.png'
import refresh from '../../Images/refresh.png'
import shell from '../../Images/shell.png'
import node from '../../Images/node.png'
import python from '../../Images/python.png'
import ruby from '../../Images/ruby.png'
import php from '../../Images/php.png'
import { Link } from 'react-router-dom';
import GetSidebar from '../GetSidebar';
import Header from '../Header';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import Footer from '../Footer';
import bgnew from '../../Images/bgnew.png'
import guideimg from '../../Images/guide.png'
import Layout from '../Layout/Layout';

const CreateWallet =()=> {
  const [phrase,setPhrase] = useState();
  const [success,setSuccess] = useState();
  const [timestamp,setTimeStamp] = useState()
  const [address,setAddress] = useState()
  const [pk,setPk] = useState()
  const [res,setRes] = useState()
  const code = "console.log('Code Mirror!');"



  const rubyCode = `    const axios = require('axios')
  const pass = "your_passphrase"
  axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
  .then(res=>{
      console.log(res.data)
  })`

    useEffect(()=>{
      let phrase = ''
      var passPh = generatePassPhrase(10)
      for(let i=0;i<passPh.length;i++){
        if(i===passPh.length-1){
          phrase += `${passPh[i]}`
        }
        else{
          phrase += `${passPh[i]} ${''}`
        }
      }
      setPhrase(phrase)
    },[])

    const tryit = ()=>{
      var dt = new Date()
      var myDate = Date.parse(dt);
      setTimeStamp(myDate)

        // const axios = require('axios')
        // const pass = "your_passphrase"
        // axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
        // .then(res=>{
        //     console.log(res.data)
        // })

      if(phrase){
        axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{
          pass:phrase,
    })
        .then(res=>{
          var encrypted = CryptoJS.AES.encrypt(res.data.PrivateKey, process.env.REACT_APP_encryptionSecretKey).toString();
          var bytes = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_encryptionSecretKey);
          var decryptedImportedPK = bytes.toString(CryptoJS.enc.Utf8);
          // console.log(decryptedImportedPK)
          // console.log(res,'data')
          sessionStorage.setItem('pk', encrypted);
          sessionStorage.setItem('address', res.data.Address);
          setAddress(res.data.Address)
          setPk(decryptedImportedPK)
          setRes(JSON.stringify(res,null,2))
          
          var address = res.data.Address
          if(address){
            setSuccess(true)
          }
          // console.log(address)
          // setTimeout(()=>{
          //   window.location.href = '/credentials'
          // },1000)
        })
      }
      // console.log(phrase)
      
    }
  
  return (
      <Layout>      
 
       <div className="row">
       <div class='col-md-7 col-sm-12 m-0 rowBorder' >
              <h3 className='boldhed'>Create Wallet</h3>
              <p class="mt-3">
                <span className='getbtn' style={{marginRight:'10px'}}>post</span>
                            <span className='' style={{fontSize:'12px'}}>https://testnet.sharkweb.com/register-and-broadcast-address</span>
                            </p>
                            <p style={{marginTop:'',fontSize:'15px'}}>SharkWeb Blockchain Create Wallet function provides a secure and user-friendly method for users to safeguard and oversee their cryptocurrency assets. This functionality enables users to effortlessly participate in buying, selling, swapping, and transferring cryptocurrencies, in addition to checking their balance and reviewing transaction history.</p>
                            <p style={{marginTop:'',fontSize:'15px'}}>Generating a wallet within our system initiates the creation of a new user wallet, furnishing them with a unique Wallet Address and its corresponding Private Key to ensure secure access and transactions.</p>
                            <hr style={{backgroundColor:'#2E2D34',marginTop:'25px',marginBottom:'25px'}}/>
                            {/* //<p className='logintxt'>LOG IN TO SEE FULL REQUEST HISTORY</p>   */}                          <div class="table-responsive table-borderless text-center" style={{marginTop:'',backgroundColor:'#13132E'}}>
                            <table class="table" style={{padding:'2%'}}>
              <thead style={{borderBottom:'1px solid #32383e'}}>
                <tr className='' style={{color:'#939397',}}>
                  <td scope="">Time Stamp</td>
                  <td scope="">Status Code</td>
                  <td scope="">Success</td>
                </tr>
              </thead>
              <tbody>

               { success ? <tr className='mltr'>
                  <th scope="row">{timestamp}</th>
                  <th scope="row">{success ? '200' : null}</th>
                  <th scope="row">true</th>
                
                </tr> :null}
                
                </tbody>
                </table>    
                   { !success ? <center>
                        
                    <p style={{color:'#939397'}}>Make a request to see history</p>
                    </center> : null}
                    <br/>                     

                            </div>
                            <br/>
                            <p style={{fontWeight:600,fontSize:'0.8rem'}}>Params</p>

                            <div class="table-responsive " style={{marginTop:'',backgroundColor:'#13132E'}}>
                            <table class="table table-dark table-dark2 table-bordered mb-0" style={{padding:''}}>
              <thead class="" style={{borderBottom:'',backgroundColor:'white'}}>
              <tr className='mltr2'>
                  <th scope="col" style={{backgroundColor:'#fff',color:'black',fontWeight:'700',fontSize:'1rem'}}>Field</th>
                  <th scope="col" style={{backgroundColor:'#fff',color:'black',fontWeight:'700',fontSize:'1rem'}}>Type</th>
                  <th scope="col" style={{backgroundColor:'#fff',color:'black',fontWeight:'700',fontSize:'1rem'}}>Description</th>
                </tr>
               
              </thead>
              <tbody>
                <tr className='mltr2'>
                  <th scope="row">pass</th>
                  <th scope="row">string</th>
                  <th scope="row">Being Sync Block number</th>    
                </tr>
                
              </tbody>
                </table>    
        
                           </div>
                           <br />
                            <p style={{fontWeight:600,fontSize:'0.8rem'}}>Returns</p>

                            <div class="table-responsive " style={{marginTop:'',backgroundColor:'#13132E'}}>
                            <table class="table table-dark table-dark2 table-bordered mb-0" style={{padding:''}}>
              <thead class="" style={{borderBottom:'',backgroundColor:'white'}}>
              <tr className='mltr2'>
                  <th scope="col" style={{backgroundColor:'#fff',color:'black',fontWeight:'700',fontSize:'1rem'}}>Field</th>
                  <th scope="col" style={{backgroundColor:'#fff',color:'black',fontWeight:'700',fontSize:'1rem'}}>Type</th>
                  <th scope="col" style={{backgroundColor:'#fff',color:'black',fontWeight:'700',fontSize:'1rem'}}>Description</th>
                </tr>
               
              </thead>
              <tbody>
                <tr className='mltr2'>
                  <th scope="row">Address</th>
                  <th scope="row">string</th>
                  <th scope="row">Being Sync Block number</th>    
                </tr>
                <tr className='mltr2'>
                  <th scope="row">PrivateKey</th>
                  <th scope="row">string</th>
                  <th scope="row">Latest block information including block height and block id</th>    
                </tr>
                {/* <tr className='mltr2'>
                  <th scope="row">SolidityBlock</th>
                  <th scope="row">string</th>
                  <th scope="row">Latest solidified block information including block height and id</th>    
                </tr>
                <tr className='mltr2'>
                  <th scope="row">SolidityBlock</th>
                  <th scope="row">string</th>
                  <th scope="row">Latest solidified block information including block height and id</th>    
                </tr> */}
              </tbody>
                </table>    
        
          </div>
          <br/>
          <p style={{fontWeight:600,fontSize:'0.8rem'}}>RESPONSES</p>  

          <div style={{backgroundColor:"#13132e",paddingLeft:'5%',paddingRight:'5%',paddingTop:'2%',paddingBottom:'2%',borderBottom:'1px solid #32383e'}}>
            <div class="">
                <p style={{marginBottom:'0px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'#17c933',marginRight:'5px'}}></i>
                <span>200</span>
                <img src={expand} style={{float:'right',fontSize:'',height:'2vh',marginTop:'15px',cursor:'pointer'}} aria-hidden="true"/>
                </p>
                <small style={{color:'#939397'}}>200</small>
            </div>
          </div>
          <div style={{backgroundColor:"#13132e",paddingLeft:'5%',paddingRight:'5%',paddingTop:'2%',paddingBottom:'2%',borderBottom:'1px solid #32383e'}}>
            <div class="">
                <p style={{marginBottom:'0px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'red',marginRight:'5px'}}></i>
                <span>400</span>
                <img src={expand} style={{float:'right',fontSize:'',height:'2vh',marginTop:'15px',cursor:'pointer'}} aria-hidden="true"/>
                </p>
                <small style={{color:'#939397'}}>400</small>
            </div>
          </div>
          
        <br/>
          <p className='logintxt'>  <img src={refresh} style={{height:'2.5vh',marginRight:'5px'}}/>updated 1 months ago</p>
          <hr style={{backgroundColor:'#2E2D34',marginTop:'25px',marginBottom:'25px'}}/>
                           
          {/* <p style={{float:'right',fontSize:'14px',cursor:'pointer',marginBottom:'0px'}}>GetBurmAIFI <img src={arrow} style={{height:'1.5vh',marginLeft:'5px'}}/></p> */}
            </div>
            <div class='col-md-5 col-sm-12 m-0 mobilepad0'>
            <div style={{padding:'5%'}}>
              
            <div class="cardShell" style={{fontSize:'0.8rem'}}>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <p></p>
                    <p></p>
                  </div>
                  <hr color='#fff' style={{margin:0}} />
                  <span>Installation</span> <br />
                  <span>$ <span style={{color:'#32CD32'}}> npm install axios</span></span>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <p>Request</p>
                    <p>Examples</p>
                  </div>
                  <ol style={{marginLeft:'-24px',height:'100px',overflowY:'auto'}}>
                  {
                rubyCode.split('\n').map((line,i)=>(
                    <li style={{fontSize:'0.7rem',color:'#32CD32'}} key={i}>{line}</li>

                    ))
                 }
                  </ol>
                  <div style={{display:'flex',justifyContent:'space-between',marginTop:'1rem'}}>
                    <div><i style={{cursor:'pointer'}} class="fa fa-paste"></i></div>
                    <button onClick={tryit} style={{backgroundColor:'#3571E9',color:'#fff',borderRadius:'0.3rem',width:'4rem',border:'none',padding:'-2px 1rem'}}>Try it!</button>
                  </div>
            </div>
            <br/> 
            <div class="cardResponse">
            <div style={{borderBottom:'1px solid #32383e',paddingTop:'3%',paddingBottom:'3%',paddingLeft:'5%',paddingRight:'5%',borderRadius:'.25rem',fontSize:'14px'}}>
            <span>RESPONSE</span>
            <span style={{float:'right'}}>EXAMPLES</span>
            </div>
            <center style={{padding:'1% 0 3% 0',color:'#939397'}}>

               { address ? 
               <CodeMirror
               value={res}
               height="280px"
               extensions={[langs.json()]}
               style={{backgroundColor:'#13132E'}}
             />
                  : null}
                
                

                {/* <p style={{fontSize:'15px'}}>Click <Link to="">Try It!</Link>  to start a request and see the
response here! Or choose an example:
<br/><br/>
application/json</p> */}
         { success ?
            <span style={{marginBottom:'0px',color:'white',fontSize:'14px',marginRight:'25px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'#17c933',marginRight:'5px'}}></i>
                <span>200 - Result</span>
             </span> 
         : null}

        {!success ? 
                <span style={{marginBottom:'0px',color:'white',fontSize:'14px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'red',marginRight:'5px'}}></i>
                <span>400 - Result</span> 
                </span>
        :null }
            </center>
            </div>
            </div>
            </div>
       </div>
    
 
     </Layout>
  )


}
export default CreateWallet

