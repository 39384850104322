import React, { Component, useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../../css/style.css'
import expand from '../../Images/expand.png'
import arrow from '../../Images/arrow.png'
import refresh from '../../Images/refresh.png'
import shell from '../../Images/shell.png'
import node from '../../Images/node.png'
import python from '../../Images/python.png'
import ruby from '../../Images/ruby.png'
import php from '../../Images/php.png'
import { Link } from 'react-router-dom';
import GetSidebar from '../GetSidebar';
import Header from '../Header';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import Footer from '../Footer';
import Layout from '../Layout/Layout';



const Dapps =()=> {
  const [phrase,setPhrase] = useState();
  const [success,setSuccess] = useState();
  const [timestamp,setTimeStamp] = useState()
  const [data,setData] = useState()
  const [res,setRes] = useState()


  const rubyCode = `const axios = require('axios')
   axios.post("https://deploy.sharkweb.com/api/data_txn",{
    privatekey:'U2FsdGVk/xxxxxxxxxxxxxxxxxxxxxxFjMVRsnhi0ng==',
    pass:'peel terrificxxxxxxxxxxxxxxxxxxchase grieving devilish',
    data:{"field":"value"},
    api_key:'dd656xxxxxxxxxxx4847a',
    _id:'65607cxxxxxxxxxxxx9d8dcd25'
   })
  .then(res=>{
    if(res.data){
      console.log(res.data)
           }
        }) `

    const tryit = ()=>{
      var dt = new Date()
      var myDate = Date.parse(dt);
      setTimeStamp(myDate)
        axios.post("https://deploy.sharkweb.com/api/data_txn",{
            privatekey:'U2FsdGVkX1/E4/UDglcb5g1/3CWC7q1+my7ljy+o/nAqr4DUzqFdhAdS9eMzG8scNMiQtaezkTFjMVRsnhi0ng==',
            pass:'peel terrific talented follow lunchroom prefer moreover chase grieving devilish',
            data:'',
            api_key:'dd6564a0-14b3-47cc-8c60-13379e14847a',
            _id:'65607cf5568d95429d8dcd25'
        })
        .then(res=>{
          setRes(JSON.stringify(res,null,2) )
          if(res.data){
            setData(res.data)
          }
        })      
    }
  
  return (
          <Layout>
          <div className="row">
            <div class='col-md-7 col-sm-12 m-0 rowBorder' >
            {/* <br/> */}
              <h3 className='boldhed'>dApps</h3>
              <p class="mt-3">
                <span className='getbtn' style={{marginRight:'10px'}}>post</span>
                            <span className='' style={{fontSize:'12px'}}>https://deploy.sharkweb.com/api/data_txn</span>
                            </p>
                            <p style={{marginTop:'',fontSize:'15px'}}>The dApps feature is designed to be user-friendly, with an intuitive interface that allows users to easily access their cryptocurrency assets. The feature includes clear and concise instructions for accessing and using the feature, as well as a help section for users who may need additional support.</p>
                            <p style={{marginTop:'',fontSize:'15px'}}>The execution of a "dApps creation" command within our system elicits comprehensive details regarding the present state of the node, specifically confirming its status.</p>
                            <hr style={{backgroundColor:'#2E2D34',marginTop:'25px',marginBottom:'25px'}}/>
                            {/* <p className='logintxt'>LOG IN TO SEE FULL REQUEST HISTORY</p> */}
                    
                            <br/>

                            {/* <div class="table-responsive " style={{marginTop:'',backgroundColor:'#13132E'}}>
                  
        
          </div>
          <br/> */}
          {/* <p style={{fontWeight:600,fontSize:'0.8rem'}}>RESPONSES</p>  
          <div style={{backgroundColor:"#13132e",paddingLeft:'5%',paddingRight:'5%',paddingTop:'2%',paddingBottom:'2%',borderBottom:'1px solid #32383e'}}>
            <div class="">
                <p style={{marginBottom:'0px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'#17c933',marginRight:'5px'}}></i>
                <span>200</span>
                <img src={expand} style={{float:'right',fontSize:'',height:'2vh',marginTop:'15px',cursor:'pointer'}} aria-hidden="true"/>
</p>
<small style={{color:'#939397'}}>200</small>
            </div>
          </div> */}
          {/* <div style={{backgroundColor:"#13132e",paddingLeft:'5%',paddingRight:'5%',paddingTop:'2%',paddingBottom:'2%',borderBottom:'1px solid #32383e'}}>
            <div class="">
                <p style={{marginBottom:'0px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'red',marginRight:'5px'}}></i>
                <span>400</span>
                <img src={expand} style={{float:'right',fontSize:'',height:'2vh',marginTop:'15px',cursor:'pointer'}} aria-hidden="true"/>
</p>
<small style={{color:'#939397'}}>400</small>
            </div>
          </div>
        <br/> */}
          {/* <p className='logintxt'>  <img src={refresh} style={{height:'2.5vh',marginRight:'5px'}}/>updated 1 months ago</p> */}
          {/* <hr style={{backgroundColor:'#2E2D34',marginTop:'25px',marginBottom:'25px'}}/> */}
                           
          {/* <p style={{float:'right',fontSize:'14px',cursor:'pointer',marginBottom:'0px'}}>GetBurmAIFI <img src={arrow} style={{height:'1.5vh',marginLeft:'5px'}}/></p> */}
            </div>
            <div class='col-md-5 col-sm-12 m-0 mobilepad0'>
            <div style={{padding:'5%'}}>
                {/* <br/>   <br/>
            <p style={{marginTop:'',fontSize:'18px'}}>LANGUAGE</p>
            <div class="row">
                <div class="col-2 pr-0">
                    <div class="language">
                        <center>
                        <img src={shell} style={{height:'2vh'}}/>
                        <p class="f10" style={{margin:'0px',fontSize:'14px'}}>Shell</p>
                        </center>
                    </div>
                </div>
                <div class="col-2 pr-0">
                    <div class="language">
                        <center>
                        <img src={node} style={{height:'2vh'}}/>
                        <p class="f10" style={{margin:'0px',fontSize:'14px'}}>Node</p>
                        </center>
                    </div>
                </div>
                <div class="col-2 pr-0">
                    <div class="language">
                        <center>
                        <img src={ruby} style={{height:'2vh'}}/>
                        <p class="f10" style={{margin:'0px',fontSize:'14px'}}>Ruby</p>
                        </center>
                    </div>
                </div>    
                  <div class="col-2 pr-0">
                    <div class="language">
                        <center>
                        <img src={php} style={{height:'2vh'}}/>
                        <p  class="f10" style={{margin:'0px',fontSize:'14px'}}>Php</p>
                        </center>
                    </div>
                </div>
                <div class="col-2 pr-0">
                    <div class="language">
                        <center>
                        <img src={python} style={{height:'2vh'}}/>
                        <p class="f10" style={{margin:'0px',fontSize:'14px'}}>Python</p>
                        </center>
                    </div>
                </div>

                <div class="col-2 pr-0 text-center">
                   <div style={{padding:'3%'}}>
                   <i class="fa fa-ellipsis-v" style={{color:'white',marginTop:'20px',cursor:'pointer',float:''}}></i>
                   </div>
                </div>

                
            </div> */}
            {/* <br/> */}
            <div class="cardShell" style={{fontSize:'0.8rem'}}>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <p></p>
                    <p></p>
                  </div>
                  <hr color='#fff' style={{margin:0}} />
                  <span>Installation</span> <br />
                  <span>$ <span style={{color:'#32CD32'}}>npm install axios</span></span>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <p>Request</p>
                    <p>Examples</p>
                  </div>
                  <ol style={{marginLeft:'-24px',height:'200px',overflowY:'auto'}}>
                  {
                rubyCode.split('\n').map((line,i)=>(
                    <li style={{fontSize:'0.7rem',color:'#32CD32'}} key={i}>{line}</li>

                    ))
                 }
                  </ol>
                  <div style={{display:'flex',justifyContent:'space-between',marginTop:'1rem'}}>
                    {/* <div><i style={{cursor:'pointer'}} class="fa fa-paste"></i></div> */}
                    {/* <button onClick={tryit} style={{backgroundColor:'#3571E9',color:'#fff',borderRadius:'0.3rem',width:'4rem',border:'none',padding:'-2px 1rem'}}>Try it!</button> */}
                  </div>
            </div>
            <br/> 
            <div class="cardResponse">
            <div style={{borderBottom:'1px solid #32383e',paddingTop:'3%',paddingBottom:'3%',paddingLeft:'5%',paddingRight:'5%',borderRadius:'.25rem',fontSize:'14px'}}>
            <span>RESPONSE</span>
            <span style={{float:'right'}}>EXAMPLES</span>
            </div>
            <center style={{padding:'1% 0 3% 0',color:'#939397'}}>
            { data ? 

              <CodeMirror
              value={res}
              height="280px"
              style={{backgroundColor:'#13132E'}}
              extensions={[langs.json()]}

              />
              
            : null}
                {/* <p style={{fontSize:'15px'}}>Click <Link to="">Try It!</Link>  to start a request and see the
response here! Or choose an example:
<br/><br/>
application/json</p> */}
{ data ?<span style={{marginBottom:'0px',color:'white',fontSize:'14px',marginRight:'25px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'#17c933',marginRight:'5px'}}></i>
                <span>200 - Result</span>
                </span> : null}
                {!data ? 
                <span style={{marginBottom:'0px',color:'white',fontSize:'14px'}}><i class="fa fa-circle" style={{fontSize:'12px',color:'red',marginRight:'5px'}}></i>
                <span>400 - Result</span> 
                </span>:null }
            </center>
            </div>
            </div>
            </div>
            </div>
          </Layout>
 
  )


}
export default Dapps

