import React, { Component, useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../css/style.css'
import expand from '../Images/expand.png'
import arrow from '../Images/arrow.png'
import refresh from '../Images/refresh.png'
import shell from '../Images/shell.png'
import node from '../Images/node.png'
import python from '../Images/python.png'
import ruby from '../Images/ruby.png'
import php from '../Images/php.png'
import { Link } from 'react-router-dom';
import GetSidebar from './GetSidebar';
import Header from './Header';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import Footer from './Footer';
import ss from '../Images/ss1.png'


const Resources =()=> {

  
  
  return (
    <div className="bgGetNode" >
       <Header/>
       <div class="row w-100 m-0">
        <div class='col-md-3 col-sm-12 p-0'>
           
            <GetSidebar/>
          
        </div>
        <div class='col-md-9 col-sm-12 m-0 rowBorder' style={{height:'90vh'}} >
            {/* <br/> */}
              <h3 className='boldhed' style={{fontSize:'3rem'}}>Resources</h3>
              <p style={{color:'gray'}}>Last edited on December 15, 2022</p>
              <p class="mt-3">
                <p>Here are more resources for a deeper understanding of Sharknode and related topics.</p>
              </p>  {/* <br /> */}
             
              <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Read   </p>

                    <Link to=''>https://link-to-be-added</Link>

              <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Watch   </p>

              <Link to=''>https://link-to-be-added</Link>

             

          

          {/* <p style={{float:'right',fontSize:'14px',cursor:'pointer',marginBottom:'0px'}}>GetBurmAIFI <img src={arrow} style={{height:'1.5vh',marginLeft:'5px'}}/></p> */}
            </div>
           
       </div>
       <Footer/>
    </div>
  )


}
export default Resources

