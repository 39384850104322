import React, { Component, useEffect, useState }  from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { generatePassPhrase } from 'passphrase-generator';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import '../css/style.css'
import expand from '../Images/expand.png'
import arrow from '../Images/arrow.png'
import refresh from '../Images/refresh.png'
import shell from '../Images/shell.png'
import node from '../Images/node.png'
import python from '../Images/python.png'
import ruby from '../Images/ruby.png'
import php from '../Images/php.png'
import { Link } from 'react-router-dom';
import GetSidebar from './GetSidebar';
import Header from './Header';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import Footer from './Footer';
import ss from '../Images/ss1.png'


const Fundamental =()=> {
  const [phrase,setPhrase] = useState();
  const [success,setSuccess] = useState();
  const [timestamp,setTimeStamp] = useState()
  const [address,setAddress] = useState()
  const [pk,setPk] = useState()
  const [res,setRes] = useState()
  const code = "console.log('Code Mirror!');"



  const rubyCode = `    const axios = require('axios')
  const pass = "your_passphrase"
  axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
  .then(res=>{
      console.log(res.data)
  })`

    useEffect(()=>{
      let phrase = ''
      var passPh = generatePassPhrase(10)
      for(let i=0;i<passPh.length;i++){
        if(i===passPh.length-1){
          phrase += `${passPh[i]}`
        }
        else{
          phrase += `${passPh[i]} ${''}`
        }
      }
      setPhrase(phrase)
    },[])

    const tryit = ()=>{
      var dt = new Date()
      var myDate = Date.parse(dt);
      setTimeStamp(myDate)

        // const axios = require('axios')
        // const pass = "your_passphrase"
        // axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{pass:pass})
        // .then(res=>{
        //     console.log(res.data)
        // })

      if(phrase){
        axios.post("https://testnet.sharkweb.com/register-and-broadcast-address",{
          pass:phrase,
    })
        .then(res=>{
          var encrypted = CryptoJS.AES.encrypt(res.data.PrivateKey, process.env.REACT_APP_encryptionSecretKey).toString();
          var bytes = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_encryptionSecretKey);
          var decryptedImportedPK = bytes.toString(CryptoJS.enc.Utf8);
          // console.log(decryptedImportedPK)
          // console.log(res,'data')
          sessionStorage.setItem('pk', encrypted);
          sessionStorage.setItem('address', res.data.Address);
          setAddress(res.data.Address)
          setPk(decryptedImportedPK)
          setRes(JSON.stringify(res,null,2))
          
          var address = res.data.Address
          if(address){
            setSuccess(true)
          }
          // console.log(address)
          // setTimeout(()=>{
          //   window.location.href = '/credentials'
          // },1000)
        })
      }
      // console.log(phrase)
      
    }
  
  return (
    <div className="bgGetNode" >
       <Header/>
       <div class="row w-100 m-0">
        <div class='col-md-3 col-sm-12 p-0'>
           
            <GetSidebar/>
          
        </div>
        <div class='col-md-9 col-sm-12 m-0 rowBorder' >
            {/* <br/> */}
              <h3 className='boldhed' style={{fontSize:'3rem'}}>Getting started with SharkWeb</h3>
              <p style={{color:'gray'}}>Last edited on December 15, 2022</p>
              <p class="mt-3">
                <p>This page explains how to set up Sharknode and execute some basic tasks using the command line tools. In order to use Sharknode, the software must first be installed. There are several ways Sharknode can be installed depending on the operating system and the user's choice of installation method, for example using a package manager, container or building from source. Instructions for installing Sharknode can be found on the "Install and Build" pages.</p>
              </p>  {/* <br /> */}
              <p>Sharknode also needs to be connected to a consensus client in order to function as an Sharkweb node. The tutorial on this page assumes Sharknode and a consensus client have been installed successfully and that a firewall has been configured to block external traffic to the JSON-RPC port 8545 see Security.</p>
              <p>This page provides step-by-step instructions covering the fundamentals of using Sharknode. This includes generating accounts, joining an Sharkweb network, syncing the blockchain and sending ether between accounts. This tutorial uses Clef. Clef is an account management tool external to Sharknode itself that allows users to sign transactions. It is developed and maintained by the Sharknode team.</p>
               
                <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Prerequisites  </p>
                <p>In order to get the most value from the tutorials on this page, the following skills are necessary:</p>
              <ul>
                <li>Experience using the command line</li>
                <li>Basic knowledge about Sharkweb and testnets</li>
                <li>Basic knowledge about HTTP and JavaScript</li>
                <li>Basic knowledge of node architecture and consensus clients</li>
              </ul>

              <p>Users that need to revisit these fundamentals can find helpful resources relating to the command line here, Sharkweb and its testnets <Link>here</Link> , http <Link>here</Link>  and Javascript <Link>here</Link> . Information on node architecture can be found <Link>here</Link>  and our guide for configuring Sharknode to connect to a consensus client is <Link>here.</Link>   </p>
              
              
              <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Step 1: Generating accounts   </p>
              <p>There are several methods for generating accounts in Sharknode. This tutorial demonstrates how to generate accounts using Clef, as this is considered best practice, largely because it decouples the users' key management from Sharknode, making it more modular and flexible. It can also be run from secure USB sticks or virtual machines, offering security benefits. For convenience, this tutorial will execute Clef on the same computer that will also run Sharknode, although more secure options are available (see <Link> here </Link>).</p>
              <p>An account is a pair of keys (public and private). Clef needs to know where to save these keys to so that they can be retrieved later. This information is passed to Clef as an argument. This is achieved using the following command:</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />

            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Step 2: Start SharkNode </p>
            <p>The previous commands used Clef's newaccount function to add new key pairs to the keystore. Clef uses the private key(s) saved in the keystore to sign transactions. In order to do this, Clef needs to be started and left running while Sharknode is running simultaneously, so that the two programs can communicate between one another.</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />

            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Step 3: Get Testnet Coin Name  </p>
            <p>In order to make some transactions, the user must fund their account with ether. On Sharkweb mainnet, ether can only be obtained in three ways: 1) by receiving it as a reward for mining/validating; 2) receiving it in a transfer from another Sharkweb user or contract; 3) receiving it from an exchange, having paid for it with fiat money. On Sharkweb testnets, the ether has no real world value so it 4) can be made freely available via faucets. Faucets allow users to request a transfer of testnet ether to their account.</p>
            <p>The address generated by Clef in Step 1 can be pasted into the Paradigm Multifaucet faucet here. The faucet adds Sepolia ETH (not real ETH) to the given address. In the next steps Sharknode will be used to check that the ether has been sent to the given address and send some of it to the second address created earlier.</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />

            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Step 4: Interact with SharkNode  </p>
            <p>For interacting with the blockchain, Sharknode provides JSON-RPC APIs. JSON-RPC is a way to execute specific tasks by sending instructions to Sharknode in the form of JSON objects. RPC stands for "Remote Procedure Call" and it refers to the ability to send these JSON-encoded instructions from locations outside of those managed by Sharknode. It is possible to interact with Sharknode by sending these JSON encoded instructions directly over Sharknode's exposed http port using tools like Curl. However, this is somewhat user-unfriendly and error-prone, especially for more complex instructions. For this reason, there are a set of libraries built on top of JSON-RPC that provide a more user-friendly interface for interacting with Sharknode. One of the most widely used is Web3.js.</p>
            <p>Sharknode provides a Javascript console that exposes the Web3.js API. This means that with Sharknode running in one terminal, a Javascript environment can be opened in another allowing the user to interact with Sharknode using Web3.js. There are three transport protocols that can be used to connect the Javascript environment to Sharknode:</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />

            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>List of accounts  </p>
            <p>In this tutorial, the accounts are managed using Clef. This means that requesting information about the accounts requires explicit approval in Clef, which should still be running in its own terminal. Earlier in this tutorial, two accounts were created using Clef. The following command will display the addresses of those two accounts and any others that might have been added to the keystore before or since.</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />

            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Checking account balance. </p>
            <p>Having confirmed that the two addresses created earlier are indeed in the keystore and accessible through the Javascript console, it is possible to retrieve information about how much ether they own. The Sepolia faucet should have sent 0.05 ETH to the address provided, meaning that the balance of one of the accounts should be at least 0.05 ether and the other should be 0. There are other faucets available that may dispense more ETH per request, and multiple requests can be made to accumulate more ETH. The following command displays the account balance in the console:</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />

            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Send coin to another account </p>
            <p>The command eth.sendTransaction can be used to send some ether from one address to another. This command takes three arguments: from, to and value. These define the sender and recipient addresses (as strings) and the amount of Wei to transfer. It is far less error prone to enter the transaction value in units of ether rather than Wei, so the value field can take the return value from the toWei function. The following command, run in the Javascript console, sends 0.1 ether from one of the accounts in the Clef keystore to the other. Note that the addresses here are examples - the user must replace the address in the from field with the address currently owning 1 ether, and the address in the to field with the address currently holding 0 ether.</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />

            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Checking the transaction hash  </p>
            <p>The transaction hash is a unique identifier for this specific transaction that can be used later to retrieve the transaction details. For example, the transaction details can be viewed by pasting this hash into the Sepolia block explorer. The same information can also be retrieved directly from the Sharknode node. The hash returned in the previous step can be provided as an argument to eth.getTransaction to return the transaction information:</p>
            <img src={ss} style={{width:'100%',height:'10rem'}} alt="" />


            <p style={{fontSize:'2.2rem',fontWeight:'700'}}>Summary  </p>
            <p>This tutorial has demonstrated how to generate accounts using Clef, fund them with testnet ether and use those accounts to interact with Sharkweb (Sepolia) through a Sharknode node. Checking account balances, sending transactions and retrieving transaction details were explained using the web3.js library via the Sharknode console and using the JSON-RPC directly using Curl. For more detailed information about Clef, please see the Clef docs. </p>

          {/* <p style={{float:'right',fontSize:'14px',cursor:'pointer',marginBottom:'0px'}}>GetBurmAIFI <img src={arrow} style={{height:'1.5vh',marginLeft:'5px'}}/></p> */}
            </div>
           
       </div>
       <Footer/>
    </div>
  )


}
export default Fundamental

